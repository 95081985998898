import React from "react"
import { graphql, PageProps } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

import Layout from "../components/layout"
import { Form, Description } from "../components/contact"
import { ContactQuery } from "./__generated__/ContactQuery"

export default ({ data, location }: PageProps<ContactQuery>) => {
    const api_url = data.site.siteMetadata.contact.api_url
    const hasContactForm = api_url
    return (
        <Layout
            seo={{
                title: "各種連絡",
                description:
                    "新聞配達の一時休止やお引越し・お試し購読のお申込みなどを受け付けております",
            }}
            location={location}
        >
            <div className="container mx-auto py-12">
                <div className="title py-12 text-center">
                    <h2 className="font-black text-5xl text-color-4">
                        各種連絡
                    </h2>
                    <h5 className="font-black text-l text-color-3">
                        <a href="/privacy-policy">個人情報の取り扱いについて</a>
                    </h5>
                </div>
                <div className="flex flex-col pb-40">
                    {hasContactForm && (
                        <div className="w-full lg:w-2/3 px-6 mx-auto">
                            <Form
                                api={api_url}
                                data={data.site.siteMetadata.contact}
                            />
                        </div>
                    )}
                    <div className={`w-full lg:w-2/3 px-6 mx-auto pt-8`}>
                        <Description data={data.site.siteMetadata.contact} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ContactQuery {
        site {
            siteMetadata {
                contact {
                    api_url
                    description
                    mail
                    phone
                    free
                    address
                    code
                }
            }
        }
    }
`
